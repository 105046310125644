import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/*
 * @Author: thl
 * @Date: 2023-11-08 14:45:11
 * @LastEditors: yyz
 * @LastEditTime: 2024-10-28 15:54:46
 */
export var customerRouter = [{
  path: '/customer-details',
  component: function component() {
    return import('@/views/group-customers-information-new/index.vue');
  },
  meta: {
    title: '群客户资料',
    keepAlive: false
  },
  children: []
}, {
  path: '/choose-project',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/chooseProject.vue');
  },
  meta: {
    title: '选择项目',
    keepAlive: false
  },
  children: []
}, {
  path: '/deal-order-detail',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/dealOrderDetail.vue');
  },
  meta: {
    title: '订单详情',
    keepAlive: false
  },
  children: []
}, {
  path: '/demand-detail',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/demandDetail.vue');
  },
  meta: {
    title: '需求详情',
    keepAlive: false
  },
  children: []
}, {
  path: '/set-programmanager',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/setProgramManager.vue');
  },
  meta: {
    title: '配置方案经理',
    keepAlive: false
  },
  children: []
},
// 写跟进
{
  path: '/customer-write-follow',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/write-follow/index.vue');
  },
  meta: {
    title: '写跟进',
    keepAlive: false
  },
  children: []
},
// 常用语
{
  path: '/cutomer-phrasePage',
  component: function component() {
    return import('@/views/group-customers-information-new/pages/write-follow/page/phrase-page.vue');
  },
  meta: {
    title: '写跟进',
    keepAlive: false
  },
  children: []
}, {
  path: '/pull-a-group',
  component: function component() {
    return import('@/views/pull-a-group/index.vue');
  },
  meta: {
    title: '一键拉群列表',
    keepAlive: false
  },
  children: []
},
// 生产待办
{
  path: '/production-backlog-list',
  component: function component() {
    return import('@/views/production-backlog/list.vue');
  },
  meta: {
    title: '生产待办列表',
    keepAlive: false
  },
  children: []
}, {
  path: '/production-backlog-detail',
  component: function component() {
    return import('@/views/production-backlog/details.vue');
  },
  meta: {
    title: '生产待办详情',
    keepAlive: false
  },
  children: []
}];