import "core-js/modules/es.array.find.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.object.to-string.js";
// 根据环境不同引入不同secret,corpid
import { Secret, baseApi, addressUrl, authUrl, initApi } from '@/config';
import axios from 'axios';
import { login } from '@/api/user';
import { GetRequest } from '@/utils/index';
var Base64 = require('js-base64').Base64;
import request from '@/utils/request';
import wrchatCropIdList from '@/utils/config/wechatCropConfig';

/**
 * @description:获取时间戳
 * 时间戳从1970年1月1日00:00:00至今的秒数
 */
export var timeStamp = function timeStamp() {
  var d = new Date();
  var date = d.getTime(); //getTime()得到的是微秒， 需要换算成秒
  return date;
};

/**
 * @description:获取随机字符串32位
 * **/
export var nonceStr = function nonceStr(len) {
  len = len || 32;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length;
  var pwd = '';
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

/**
 * @description:获取应用数据
 * @params:{corpid}-企业ID
 * @params:{corpsecret}-企业微信应用scret
 * **/

export function getAccessToekn(data) {
  var params = {};
  var a = GetRequest(window.location.href);
  var urlName = window.location.pathname;
  var corpId = sessionStorage.getItem('corpId');
  var AgentId = sessionStorage.getItem('AgentId');
  var url = '';
  var otherUrl = 'https://m-crm.zjljls.cn';
  // //浙江良驹律师事务所主体
  // if(corpId=='wwbcc57ce621e6e190'){
  //   url =  otherUrl+'/?code='+a.code+'&state='+a.state
  // }else{
  //   url =  authUrl+'/?code='+a.code+'&state='+a.state
  // }
  var newCode = wrchatCropIdList.find(function (item) {
    return item.corpId == corpId;
  });
  console.log('企微配置文件23222323', wrchatCropIdList);
  console.log('newCode', newCode);
  //非shupian.net的其它企微主体
  if (newCode) {
    console.log('当前企微主体环境', process.env.VUE_APP_ENV);
    var corpUrl = '';
    if (process.env.VUE_APP_ENV == 'staging') {
      corpUrl = newCode.tUrl;
    } else if (process.env.VUE_APP_ENV == 'beta') {
      corpUrl = newCode.pUrl;
    } else if (process.env.VUE_APP_ENV == 'production') {
      corpUrl = newCode.url;
    }
    url = corpUrl + '/?code=' + a.code + '&state=' + a.state;
  } else {
    console.log('没有匹配到对应主体', corpId);
    url = authUrl + '/?code=' + a.code + '&state=' + a.state;
  }
  // let url = authUrl+'/?code='+a.code+'&state='+a.state
  console.log('authUrl', authUrl);
  console.log('urlName', urlName);
  console.log('url', url);
  console.log('a', a);
  url = Base64.encode(url);
  var urlState = a.state.split('-');
  sessionStorage.setItem('corpId', urlState[0]);
  sessionStorage.setItem('AgentId', urlState[1]);
  sessionStorage.setItem('urlCode', a.code);
  localStorage.setItem('urlCode', a.code);

  // ?agentConfig=agent_config&corpid='+corpId+'&isRefresh=true&url='+url
  return request({
    url: baseApi + '/api/crisps-crm/service/nk/v1/scrm/ExternalContact/getSignature.do',
    method: 'get',
    params: {
      agentConfig: 'agent_config',
      corpid: corpId,
      isRefresh: false,
      url: url
    }
  });
}

/**
 * @description:获取企业数据
 * @params:{corpid}-企业ID
 * @params:{corpsecret}-企业微信企业scret
 * **/

export function getAccessFirm() {
  var params = {};
  var urlName = window.location.pathname;
  var corpId = sessionStorage.getItem('corpId');
  var AgentId = sessionStorage.getItem('AgentId');
  var a = GetRequest(window.location.href);
  var url = '';
  // let otherUrl= 'https://m-crm.zjljls.cn'
  // //浙江良驹律师事务所主体
  // if(corpId=='wwbcc57ce621e6e190'){
  //   url =  otherUrl+'/?code='+a.code+'&state='+a.state
  // }else{
  //   url =  authUrl+'/?code='+a.code+'&state='+a.state
  // }
  var newCode = wrchatCropIdList.find(function (item) {
    return item.corpId == corpId;
  });

  //非shupian.net的其它企微主体
  if (newCode) {
    var corpUrl = '';
    if (process.env.VUE_APP_ENV == 'staging') {
      corpUrl = newCode.tUrl;
    } else if (process.env.VUE_APP_ENV == 'beta') {
      corpUrl = newCode.pUrl;
    } else if (process.env.VUE_APP_ENV == 'production') {
      corpUrl = newCode.url;
    }
    url = corpUrl + '/?code=' + a.code + '&state=' + a.state;
  } else {
    url = authUrl + '/?code=' + a.code + '&state=' + a.state;
  }
  url = Base64.encode(url);
  var urlState = a.state.split('-');
  sessionStorage.setItem('corpId', urlState[0]);
  sessionStorage.setItem('AgentId', urlState[1]);
  sessionStorage.setItem('urlCode', a.code);
  localStorage.setItem('urlCode', a.code);

  // ?agentConfig=&corpid='+corpId+'&isRefresh=true&url='+url
  return request({
    url: baseApi + '/api/crisps-crm/service/nk/v1/scrm/ExternalContact/getSignature.do',
    method: 'get',
    params: {
      agentConfig: '',
      corpid: corpId,
      isRefresh: false,
      url: url
    }
  });
}